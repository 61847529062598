(function ($) {
    // Handler for .ready() called.
    var processing, clinicData;
    /** Custom Validator**/
    $.validator.addMethod("alphaonly", function (value, element) {
        var regex = new RegExp("^[a-zA-Z ]+$"); //[a-zA-Z0-9]
        var key = value;

        if (!regex.test(key)) {
            return false;
        }
        return true;
    }, "Please use only alphabetic characters or space.");

    //Validation for ABS form
    $.validator.addMethod("alphanum", function (value, element) {
        var regex = new RegExp("^[-a-zA-Z0-9_\\-!&#*'\" ]+$"); //[a-zA-Z0-9]
        var key = value;

        if (!regex.test(key)) {
            return false;
        }
        return true;
    }, "Please use only alphanumeric characters.");

    $.validator.addMethod("notempty", function (value, element) {
        var key = value,
            tempValue = $.trim(value);

        if (tempValue == '') {
            return false;
        }
        return true;
    }, "Please enter a value.");

    $.validator.addMethod("nophp", function (value, element) {
        value = _convertSpecialChars(value);
        if (/<\?[=|php]?[\s\S]*?\?>/i.test(value)) {
            return false;
        }
        return true;
    }, "Only plain text allowed.");

    $.validator.addMethod("nohtml", function (value, element) {
        value = _convertSpecialChars(value);
        if (/<\/?[a-z][\s\S]*>/i.test(value)) {
            return false;
        }
        return true;
    }, "No html allowed.");

    function _convertSpecialChars(html) {
        var txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    }

    window.onload = window.onresize = function () {
        var cr_overlay = document.getElementsByClassName('cr-overlay');
        var window_width = window.innerWidth - 30;
        if(window_width <= 800){
            $('.img-preview-wrapper').css({'width': window_width + "px", 'height': window_width + "px"});
        }
    }

    /* Photo Filter */
    var $uploadCrop;
    $uploadCrop = $('#img-preview').croppie({ //You have to initialize croppie after showing the modal or else it won't work
        //enableExif: true,
        url: '/images/filter.png',
        mouseWheelZoom: false,
        enableResize: true,
        viewport: {
            width: '90%',
            height: '90%',
            type: 'circle',
        },
        // boundary: {
        //     width: '100%',
        //     height: '100%',
        // },
    });
    $('#picture-filter-wrapper #fld-photo').on('change', function () {
        if (this.files && this.files[0]) {
            var reader = new FileReader();
            reader.onload = function (e) {
                //if ($('#img-preview').hasClass('crop')) {
                $('#img-preview').addClass('ready');
                $uploadCrop.croppie('bind', {
                    url: e.target.result,
                }).then(function () {
                    $('#img-preview').removeClass('off');
                });
                // } else if ($('#img-preview').hasClass('cover')) {
                //     var nimg = '<img src="' + e.target.result + '" class="pending-cover img-fluid" alt="" />';
                //     $('#img-preview').html(nimg);
                // }
            }
            reader.readAsDataURL(this.files[0]);
            $('#save-crop').fadeIn();
        } else {
            $('#save-crop').fadeOut();
        }
    });

    $('#save-crop').on('click', function () {
        $uploadCrop.croppie('result', {
            type: 'canvas',
            size: 'viewport',
        }).then(function (resp) {
            $('#cimage').val(resp);
            $('#filter-form').trigger('submit');
        });
    });


    $('body').on('click', '#zip-results .cta .btn', function(e){
        e.preventDefault();
        $('#frmModal .modal-title > span').text($('.name', $(this).parents('.item')).text());
        $('#frmModal #clinic_id').val($(this).data('clinic'));
        $('#frmModal').modal('show');
    })

    $("#frmModal").on('hidden.bs.modal', function(){
        $('#frmModal .modal-title > span').text('');
        $('#frmModal #clinic_id').val('');
    });

    $("#filter-form").submit(function (event) {
        event.preventDefault(); //prevent default action 
        if(processing){
            alert('Already fetching data please wait.');
            return;
        }
        processing = true;
        //alert('Submit Form');
        // form = $('#po-profile-frm')[0];
        // eType = 'multipart/form-data';
        // contentType = false;
        // processData = false;
        //frmdata = new FormData(form);
        $('#save-crop').addClass('loading');
        var frmdata = {};
        var form = $('#filter-form')[0];
        eType = 'multipart/form-data';
        frmdata = new FormData(form);
        frmdata.append('op', 'photo_filter');
        $.ajax({
            type: 'POST',
            enctype: 'multipart/form-data',
            url: 'misc/ajax.php',
            data: frmdata,
            contentType: false,
            cache: false,
            processData: false,
        })
            .done(function (result) {
                //var res = $.parseJSON(result);
                //console.log(res);
                if (!isEmpty(result.file)) {
                    $('#result-img').html('<div><img src="' + result.file + '" alt=""/><p class="mt-2"><a href="' + result.file + '" target="_blank" class="btn btn-primary mb-2">View Image</a></p></div>');
                } else {
                    $('#result-img').html('');
                }
                $('html, body').animate({
                    scrollTop: $('#result-img').offset().top
                }, 800);
                // if (!isEmpty(result.data.success)) {
                //     if ($('.profile-setup-wrapper').length > 0) {
                //         navigatePanel($('.form-step-panel.active'));
                //     } else {
                //         if (!isEmpty(result.data.updated_experiences)) {
                //             if ($('#experience-listing').length > 0) {
                //             $('#experience-listing > ul').fadeOut().remove();
                //             $('#experience-listing').append(result.data.updated_experiences);
                //             $('#profile-edit-modal').modal('hide');
                //             }
                //             po_show_alert('Experiences updated successfully.', 'success');
                //         } else if (!isEmpty(result.data.cropped)) {
                //             //Update Crop and close modal
                //             //$('#profile-pic .photo').append('<img src="" />')
                //             //$('.profile-pic .photo').html('<img src="' + $('#cimage').val() + '" alt="" />');
                //             alert('Profile picture updated successfully.', 'success');
                //         } else {
                //             window.location = window.location.href + '?updated=1';
                //         }
                //     }
                // } else if (!isEmpty(result.data.error)) {
                //     $('#coin-form').prepend('<div id="err-msg" class="po-msg-box alert alert-danger" role="alert">' + result.data.error + '</div>');
                // }
            })
            .fail(function () {
                alert('Some unknown error occured, please try again later.', 'error');
            }).always(function () {
                frmProcessing = false;
                $('#save-crop').removeClass('loading');
            });
    });

   
    $("#zp-frm").validate({
        ignore: ".ignore",
        errorElement: "span",
        errorPlacement: function (error, element) {
            error.appendTo(element.parents('.field-row'));
        },
        highlight: function (element) {
            $(element).parents('.field-row').addClass('has-error');
        },
        rules: {
            'zip': { required: true,  minlength: 5,maxlength: 5,digits: true },
        },
        messages: {
            'zip': {required: 'Please enter a zip code.'},
        },
        invalidHandler: function (event, validator) {
            $('html, body').animate({
                scrollTop: $(validator.errorList[0].element).parents('.field-group').offset().top
            }, 800);
        },
        submitHandler: function (form) {
            //new FormData(this),
            processInlineForm(new FormData(form));
            return false;
        }
    });

    $("#state-frm").validate({
        ignore: ".ignore",
        errorElement: "span",
        errorPlacement: function (error, element) {
            error.appendTo(element.parents('.field-row'));
        },
        highlight: function (element) {
            $(element).parents('.field-row').addClass('has-error');
        },
        rules: {
            'state': { required: true},
        },
        messages: {
            'state': {required: 'Please select a state.'},
        },
        invalidHandler: function (event, validator) {
            $('html, body').animate({
                scrollTop: $(validator.errorList[0].element).parents('.field-group').offset().top
            }, 800);
        },
        submitHandler: function (form) {
            //new FormData(this),
            filterByState($('#s_state').val());
            return false;
        }
    });

    $('#s_state').on('change', function(){
        $('#search-results').html('');
    })

    $("#entry-form").validate({
        ignore: ".ignore",
        errorElement: "span",
        errorPlacement: function (error, element) {
            error.appendTo(element.parents('.field-input'));
        },
        highlight: function (element) {
            $(element).parents('.field-input').addClass('has-error');
        },
        unhighlight: function (element) {
            $(element).parents('.field-input').removeClass('has-error');
        },
        rules: {
            'entry[name]': { required: true, notempty: true, nohtml: true, nophp: true },
            'entry[email]': { required: true, email: true, notempty: true, nohtml: true, nophp: true },
            'entry[practice]': { required: true, notempty: true, nohtml: true, nophp: true },
            'entry[address]': {nohtml: true, nophp: true },
            'entry[city]': {nohtml: true, nophp: true },
            'entry[state]': {nohtml: true, nophp: true },
            'entry[zip]': { minlength: 5,maxlength: 5,digits: true },
            'entry[social]': {nohtml: true, nophp: true },
        },
        messages: {
            'entry[name]': { required: 'Please enter your name.' },
            'entry[email]': { required: 'Please enter an email address.', email: 'Please enter a valid email address.' },
        },
        invalidHandler: function (event, validator) {
            $('html, body').animate({
                scrollTop: $(validator.errorList[0].element).parents('.field-group').offset().top
            }, 800);
        },
        submitHandler: function (form) {
            //new FormData(this),
            processForm(new FormData(form));
            return false;
        }
    });

    


    //Create expression and trap all external links
    $.expr[':'].external = function (obj) {
        return !obj.href.match(/^mailto\:/)
            && (obj.hostname != location.hostname);
    };

    $('a[href$=".pdf"]').prop('target', '_blank').addClass('pdf');
    $('a:external:not(.sub)').addClass('external-link');
    $('.external-link').attr('aria-describedby', 'off-site');
    $('a[href="#"]').attr('role', 'button');
    var externalLink;

    function filterByState(state){
        if(processing){
            alert('Fetching data please wait.');
            return;
        }
        
        if(isEmpty(clinicData)){
            processing = true;
            $('#search-results').html('');
            $('#search-results').addClass('loading');
            $.ajax({
                type: "POST",
                url: 'misc/ajax.php',
                data: { op: "clinics"},
                cache: false,
            })
            .done(function (resp) {
                if(!isEmpty(resp.clinics)){
                    clinicData = resp.clinics;
                    $('#search-results').html(_buildHtml(state));
                }
            })
            .fail(function () {
                // Some Error Occured
            })
            .always(function (data) {
                processing = false;
                $('#search-results').removeClass('loading');
            });
        } else {
            $('#search-results').html(_buildHtml(state));
        }
    }

    function _buildHtml(state){
        const result = clinicData.filter((clinic) => clinic.state == state);
        var html = '';
        if(result.length > 0){
            html += '<ul>';
            result.map((item) => {
                html += '<li><strong class="d-block">' + item.organization + '</strong>' + item.address + ', ' + item.state + ', ' + item.zip + '</li>';
            }).join('');
            html += '</ul>';
        }
        if(isEmpty(html)){
            html = '<div class="text-center"><p><strong>No clinics in your state are participating this year.</strong><br />Visit the NAFC website to find a clinic near you and learn about programs they offer (and/or other days you can receive a skin health consultation).</p><p><a href="https://nafcclinics.org/" target="_blank" class="btn">Visit NAFC Website</a></p></div>';
        }
        return html;
    }

    function processInlineForm(formData) {

        if(processing){
            alert('Already fetching data please wait.');
            return;
        }
        processing = true;
        formData.append('op', 'zip-data');
        $('#zip-results').addClass('loading');
        $('#zip-results').html('');

        $.ajax({
            type: "POST",
            dataType: 'json',
            url: 'misc/ajax.php',
            data: formData,
            contentType: false,
            cache: false,
            processData:false,
        })
        .done(function (resp) {
            if (!isEmpty(resp.success)) {
                //show_zip_data(resp.coordinates, resp.clinics);
                if(!isEmpty(resp.html)){
                    $('#zip-results').html(resp.html);
                    setTimeout(function(){
                        $('html, body').animate({
                            scrollTop: $('#zip-results').offset().top - 200
                        }, 200);
                    }, 500);
                } else {
                    $('#zip-results').html('<div class="no-results"><p>No clinics in your area are participating this year. <br />To find future volunteer opportunities at your nearest clinic, visit the <strong><a href="https://nafcclinics.org/" target="_blank">NAFC website</a></strong>.</p></div>');
                }
               
            } else if (!isEmpty(resp.error)) {
                if(!isEmpty(resp.zeroResults)){
                    $('#zip-results').html('<div class="no-results"><p>No clinics in your area are participating this year. <br />To find future volunteer opportunities at your nearest clinic, visit the <strong><a href="https://nafcclinics.org/" target="_blank">NAFC website</a></strong>.</p></div>');
                } else {
                    alert(resp.error);
                }
               
            }
        })
        .fail(function () {
            // Some Error Occured
        })
        .always(function (data) {
            $('#zip-results').removeClass('loading');
            processing = false;
        });
    }
    function _radians(degrees) {
        var TAU = 2 * Math.PI;
        return degrees * TAU / 360;
    }

    function show_zip_data(coordinates, clinics) {
        var res = [];
        var miles = null;
        clinics.forEach(function (clinic, i) {
            miles =  Math.ceil(Math.acos(Math.cos(_radians(90-coordinates.lat)) *Math.cos(_radians(90-clinic.lat)) +Math.sin(_radians(90-coordinates.lat)) *Math.sin(_radians(90-clinic.lat)) *Math.cos(_radians(coordinates.lng-clinic.lng))) * 3959);
            if(miles <= 500){
                res.push({idx: i, miles: miles, clinic: clinics[i]});
            }
        });

        res.sort( function(a,b){
            return a.miles - b.miles
        } );
    }

    function processForm(formData) {
        $('#error-bucket, #success-bucket').fadeOut().remove();
        if (processing) {
            alert('Your submission is being processed, please wait.');
            return;
        }

        var button = $('.frm-submit');
        button.addClass('loading');
        processing = true;
        formData.append('op', 'form-submission');

        $.ajax({
            type: "POST",
            dataType: 'json',
            url: 'misc/ajax.php',
            data: formData,
            contentType: false,
            cache: false,
            processData:false,
            beforeSend: function (xhr) {
                $("#err").fadeOut();
            }
        })
            .done(function (resp) {
                if (!isEmpty(resp.success)) {
                    // $('#survey-form').prepend('<div id="success-bucket">Submission processed successfuly.</div>');
                    _resetform();
                    // setTimeout(function(){
                     window.location = "/thank-you";
                    // }, 5000);
                } else if (!isEmpty(resp.error)) {
                    if ($('#entry-form').length > 0) {
                        $('#entry-form').prepend('<div id="error-bucket">' + resp.error + '</div>');
                    }
                }
            })
            .fail(function () {
                // Some Error Occured
            })
            .always(function (data) {
                processing = false;
                button.removeClass('loading');
                if ($('#error-bucket').length > 0) {
                    $('#frmModal').animate({
                        scrollTop: 0
                    }, 800);
                }
            });
    }


    function _resetform(){
        $("#entry-form")[0].reset();
        if ($('#success-bucket').length > 0) {
            $('html, body').animate({
                scrollTop: $('#success-bucket').offset().top - 10
            }, 1000);
        }
    }

    function isEmpty(mixed_var) {
        mixed_var = jQuery.trim(mixed_var);
        var key;
        if (mixed_var === '' || mixed_var === 0 || mixed_var === '0' || mixed_var === null || mixed_var === false || typeof mixed_var === 'undefined') {
            return true;
        }

        if (typeof mixed_var === 'object') {
            for (key in mixed_var) {
                return false;
            }
            return true;
        }
        return false;
    }

})(jQuery);